// Your custom variables


$input-background-colour: #eff6fc; // Theme LighterAlt
$input-text-colour: #605e5c; // Type Secondary

$warning: #fff4ce;
$warning-icon: #797673;
$severe-warning: #fed9cc;
$severe-warning-icon: #d83b01;
$error-block: #fde7e9;
$error-block-icon: #a80000;
$success: #dff6dd;
$success-icon: #107c10;
