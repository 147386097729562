html,
body,
.background {
  height: 100%;
  * {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
}

body {
  height: 100%;
  background-repeat: no-repeat;
  background-color: #deecf9;
}

.background {
  height: 100%;
  // padding: 4rem;
}
.stonly-widget-wrapper {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 3rem;
  right: 3rem;
}
.login-company-banner {
  padding-bottom: 3rem;
  height: 100px;
}

.login-display {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: inherit;

  .company-banner {
    padding-bottom: 10px;
    padding-left: 0.5rem;
    width: 55%;
  }

  .atvero-logo {
    float: right;
    width: 12rem;
    padding-bottom: 0.5rem;
    margin: 2rem;
  }
  .company-logo {
    float: right;
    width: 5rem;
    padding: 1rem;
  }
  .login-logo {
    flex: 1;
  }

  .login-container {
    min-width: 350px;
    min-height: 35rem;
    height: 33rem;
    padding: 2rem;
    border-radius: 25px;
    position: relative;
    margin-left: 0;

    .login-text {
      flex: 1;
    }
    .text.bold {
      flex: 1;
      margin-bottom: 0px;
    }

    .text.blue {
      margin-top: -1rem;
    }

    .header-text {
      font-size: 3rem;
    }

    &.constrained-width {
      display: flex;
      flex-direction: column;
      max-width: 40rem;
      margin: 4rem;

      .access-granted {
        padding-bottom: 10%;
      }
    }

    .login-form {
      padding: 1rem;
      border-radius: 25px;
      margin-left: 0rem;

      .small-text {
        color: $input-text-colour;
        font-size: 14px;
      }

      .input-field {
        margin-bottom: 10px;
      }

      .spinner-border {
        float: right;
      }
      p {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 10px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }

      .btn-primary {
        width: 5rem;
        float: right;
        text-transform: none;
        border-radius: 25px;
        box-shadow: none;
        font-size: 14px;
        border: none;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        &.margin_right_1 {
          margin-right: 1rem;
        }
      }

      .request-button {
        width: 8rem;
      }

      .login-error {
        width: 17rem;
      }

      // Bootstrap .form-control is too complicated and not ReScript friendly
      .custom-form-control {
        background-color: $input-background-colour;
        padding: 0.5rem;
        padding-left: 1rem;
        width: 100%;
        font-size: 14px;
        border-radius: 25px;
        border: none;
        color: $input-text-colour;
        &:focus {
          outline: none;
          box-shadow: 0 0 0 1pt $input-text-colour;
          border-radius: 25px;
        }
        &.disabled {
          outline: none;

          &:focus {
            outline: none;
            box-shadow: 0 0 0 1pt $input-text-colour;
          }
        }
      }
    }
  }
}

.display {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;

  .atvero-logo {
    float: right;
    width: 12rem;
    padding-bottom: 0.5rem;
    margin-right: 2rem;
    margin-top: 2rem;
  }
  .company-logo {
    float: right;
    width: 5rem;
    padding: 1rem;
  }

  .files-container {
    min-width: 350px;
    margin: 2rem;
    padding: 2rem;
    border-radius: 25px;
    position: relative;
    display: flex;

    .header {
      flex: 1;
      text.bold {
        margin-bottom: 0px;
      }

      .text.blue {
        margin-top: -2rem;
      }
    }

    .align-items-start {
      flex: 3;
    }
  }

  .display-files {
    flex: 4;
    margin: 2rem;
    padding: 1rem;
    overflow: hidden;
    height: 100%;
    margin-top: 2rem;
    border-radius: 25px;

    text-decoration: none;

    .button-wrapper {
      height: 3rem;
      margin-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;

      .zip-download-progress {
        float: right;
        padding-top: 1rem;
      }
      .access-granted-text {
        float: left;
        width: 10rem;
        color: $input-text-colour;
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 10px;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      }
    }
    .zip-download {
      width: 8rem;
      float: right;
      text-transform: none;
      border: none;
      border-radius: 25px;
      box-shadow: none;
      font-size: 14px;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    }

    .files-header {
      font-weight: 600;
      font-size: 24px;
      float: left;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    }

    .files-list {
      overflow-y: scroll;
      padding: 2rem;
      padding-top: 0rem;
      flex: 2;
      height: 80%;
      width: 100%;

      .mb-1 {
        margin-bottom: 2px !important;
      }
    }

    .file-download-row {
      cursor: pointer;
      font-weight: 600;
      background-color: $input-background-colour;
      border-radius: 5px;
      padding-left: 1rem;

      padding: 0.4rem;
      font-size: 14px;

      .file-name {
        p {
          margin-bottom: 0;
        }
      }

      .download-arrow {
        float: right;
        margin-top: -0.2rem;
      }

      .progress {
        width: 50%;
        float: right;
        .download-progress {
          float: right;
        }
      }
    }
  }
}

.text {
  &.blue {
    font-size: 4rem;
  }
  &.bold {
    font-size: 4rem;
    font-weight: bold;
  }
}

.pull-right {
  float: right;
}

.alert {
  font-size: 14px;
  border-radius: 25px;
  margin-bottom: 0;
  padding: 0.5rem;
  padding-left: 1rem;
}
.alert-primary {
  background-color: None;
  border: none;
}

.alert-warning {
  color: $warning-icon;
  background-color: $warning;
  border: none;
}
.alert-danger {
  color: $error-block-icon;
  background-color: $error-block;
  border: none !important;
}

.btn-float-left {
  float: left !important; // override specificity
}
